
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import Container2fa from '@/components/views/auth/2fa/Container2fa.vue'

export default defineComponent({
  components: {
    TmButton,
    Container2fa,
  },
})
